import React, { useState, useEffect } from "react"
import { Link } from 'gatsby'
import { Input  } from '@geist-ui/react'
import { Plus, Minus } from '@geist-ui/react-icons'

import BBB from '../../static/assets/BBBLogo.png'

export default function BagelLink (props) {

 const [num, setNum] = useState(0)

 function changeQuantity(number) {
 	if(number > 0 && !props.full) {
 		props.onChange(props.bagel[0], 1);
 		setNum(num + 1);
 	}
 	else if(number > 0 && props.full) {
 		alert('Your box is full');
 	}
 	else if(number < 0) {
 	 	props.onChange(props.bagel[0], -1);
 	 	setNum(num - 1);
 	}
 }

 useEffect(() => {
     if(props.bagels) {
         setNum(props.bagels.filter((bagel) => bagel === props.bagel[0]).length)
     }
     else {
         setNum(props.full === props.bagel[0] ? 1 : 0)
     }
 }, [props.bagels, props.bagel, props.full])


  return (<>
    <div className="bagel-link">
    	<img src={props.bagel[1]} alt={props.bagel[2]} />
    	<div className="text">
    		<h6>{props.bagel[0]}</h6>
    	</div>
    	{props.select && <div className="quantity">
    		<Minus size={20} className={num < 1 ? 'disabled' : ''} onClick={() => num > 0 && changeQuantity(-1)} />
    		{num}
    		<Plus size={20} className={props.full ? 'disabled' : ''} onClick={() => !props.full && changeQuantity(1)} />
    	</div>}
    </div>
  </>)
}