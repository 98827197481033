import React, { useState, useEffect } from "react"
import { graphql, navigate } from 'gatsby'
import Helmet from 'react-helmet'
import BagelLink from '../components/BagelLink'
import { Modal, Loading, Spacer } from '@geist-ui/react'

import Bagels from '../../static/assets/Bagels.jpg'
import Bagel from '../../static/assets/Bagel.png'
import Creamcheese from '../../static/assets/Creamcheese.png'
import Coffee from '../../static/assets/Coffee.png'
import Sandwich from '../../static/assets/BagelSandwich.png'



const Index = () => {

  const [loading, setLoading] = useState(true)
  const [bagels, setBagels] = useState([])
  const [creamcheeses, setCreamcheeses] = useState([])
  const [mapLoading, setMapLoading] = useState(true)


  useEffect(() => {
    fetch(`/.netlify/functions/fauna-all/`, {
        body: JSON.stringify({
          index: 'bagels_all'
        }),
      method: 'POST'}).then((response) => {
        return response.json()}).then((response) => {
          setBagels(response.data || []);
          setLoading();
        })
     .catch(error => (console.log(error), setLoading()));
     fetch(`/.netlify/functions/fauna-all/`, {
        body: JSON.stringify({
          index: 'creamcheeses_all'
        }),
      method: 'POST'}).then((response) => {
        return response.json()}).then((response) => {
          setCreamcheeses(response.data || []);
          setLoading();
        })
     .catch(error => (console.log(error), setLoading()));
  }, [])

  useEffect(() => {
    const timeout = setTimeout(() => {
      window.StoreRocket.init({selector: ".storerocket-store-locator", account: "Y68MD51J9N"});
      setMapLoading(false);
      }, 1500);
    return () => {
     clearTimeout(timeout);
   };
}, []);

  return (
    <>
      <Helmet>
        <title>Saturday Bagel Club - Authentic NY Bagels in Calabasas and Malibu California.</title>
        <script src="https://cdn.storerocket.io/widget.js"></script>
      </Helmet>
      <div className="top-banner" style={{backgroundImage:`linear-gradient( rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0.5) ), url(${Bagels})`, backgroundSize:'cover', backgroundPosition:'50% 60%'}}>
       <h1>Fresh-Baked NY-Style Bagels Paired with Artisan Cream Cheese - for pickup every Saturday in Calabasas & Malibu</h1>
       <div className="book-buttons">
         <div className="book-button-item" onClick={() => navigate('/order/')}>
           Order Now
         </div>
       </div>
     </div>
     <div id="about" className="about">
       <h4>How it Works</h4>
       <p className="full">order a bag of bagels & cream cheese for pickup on Saturday morning</p>
      <div className="step-item">
         <div className="step">
           <img src={Bagel} alt="Bagel" width="120px" />
           <h5>Pick Your Bagels</h5>
         </div>
         <div className="item">
           <p>Choose 6 fresh-baked bagels from our core recipes and seasonal specials.</p>
           <div className="book-button-item" onClick={() => document.getElementById("bagels").scrollIntoView({behavior: "smooth"})}>
           View Types</div>
         </div>
       </div>
       <div className="step-item">
         <div className="step">
           <img src={Creamcheese} alt="Cream Cheese" width="120px" />
           <h5>Pick Your Cream Cheese</h5>
         </div>
         <div className="item">
           <p>Add one of our organic fresh-whipped cream cheese flavors.</p>
           <div className="book-button-item" onClick={() => document.getElementById("creamcheeses").scrollIntoView({behavior: "smooth"})}>
           View Flavors</div>
         </div>
       </div>
       <div className="step-item">
         <div className="step">
           <img src={Coffee} alt="Local Pickup" width="120px" />
           <h5>Pick Up Saturday</h5>
         </div>
         <div className="item">
           <p>Order by Thursday at 9AM for Saturday morning pick up at a location near you.</p>
           <div className="book-button-item" onClick={() => document.getElementById("locations").scrollIntoView({behavior: "smooth"})}>
           Find Location</div>
         </div>
       </div>
       <div className="step-item">
         <div className="step">
           <img src={Sandwich} alt="Local Pickup" width="120px" />
           <h5>Enjoy Together</h5>
         </div>
         <div className="item">
           <p>Grab your bagels to share with friends & family. Bringing people together, one bagel at a time.</p>
           <a href="https://www.instagram.com/saturdaybagelclub/" target="_blank" className="book-button-item">
           Follow Us</a>
         </div>
       </div>
     </div>
     <div className="bagel-list" id="bagels">
       <h4>NY-Style Bagels</h4>
       <p className="full">select 6 fresh-baked bagels</p>
       {bagels.length > 0 && !loading && bagels.sort((a,b) => a[0] > b[0] ? -1 : 1).map((bagel) => (
         <BagelLink bagel={bagel} />
        ))}
        {loading && <Loading />}
        <Spacer h={1} />
     </div>
     <div className="bagel-list" id="creamcheeses">
       <h4>Artisan Cream Cheese</h4>
       <p className="full">plus a tub of cream cheese</p>
       {creamcheeses.length > 0 && !loading && creamcheeses.map((creamcheese) => (
         <BagelLink bagel={creamcheese} creamcheese="true" />
        ))}
        {loading && <Loading />}
        <Spacer h={1} />
     </div>
     <div className="locations" id="locations">
       <h4>Pickup Locations</h4>
       <p className="full">pickup is on Saturday mornings from 8AM - 10AM</p>
       {mapLoading && <Loading />}
       <div className="storerocket-store-locator"></div>
     </div>
    </>
  )
}

export default Index
